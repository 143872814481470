import React from 'react';
import { connect } from 'react-redux';
import { NavLink as Link } from 'react-router-dom';
import classNames from 'classnames';
import { onlyUpdateForKeys } from 'recompose';
import AppendJobEditor from 'app/Contacts/AppendJobEditor';
import { getBrandCode, getBrandHelpUrl, getBrandLogoUrl, getBrandSmallLogoUrl, getBrandLogoAltUrl } from 'utils/brand';
import { closeAllPopup } from '../../PopupHolder';

import { selectSmsEnabled, selectSmsRegistered } from 'data/campaigns';
import { authenticate, logout } from 'data/user';
import ListManagementInfo from 'app/components/ListManagementInfo';
import LaunchControlInfo from 'app/components/LaunchControlInfo';
import UpgradeAccount from 'app/components/UpgradeAccount';
import LoanApplication from 'app/components/LoanApplication';
import SVG from 'components/base/SVG';
import Image from 'components/base/Image';
import RestrictedContent from 'app/components/RestrictedContent';
import { Permissions } from 'data/user/constants';
import { selectProfile, selectIsLoading, setChatVisible } from 'data/user';
import { withRouter } from 'react-router-dom';

import css from './style.scss';

const KEYS_TO_UPDATE = [
  'profile',
  'permissions',
  'isLoading',
  'location',
];

const Sidebar = ({ profile, isLoading, smsEnabled, authenticate, logout, setChatVisible, closeAllPopup, history, openAppendJobEditor, openLaunchControlInfo, openLoanApplication, openListManagementInfo }) => {
  const handleContactAppendClick = () => {
    handleLinkClick();
    openAppendJobEditor();
  };

  const handleSmsClick = () => {
    handleLinkClick();
    openLaunchControlInfo();
  };

  const handleLoanApplicationClick = () => {
    openLoanApplication();
  };

  const handleChatClick = () => {
    setChatVisible(true);
  };
  const clearCookiesOnLogout = () => {
    sessionStorage.removeItem('isLoggedIn')
    sessionStorage.setItem('logOutClicked', JSON.stringify(true))
      // Clear all cookies
      document.cookie.split(";").forEach((cookie) => {
      document.cookie = cookie
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
  }

  const handleTestClick = () => {
    openListManagementInfo();
  };

  const handleNavClick = (to) => {
    setTimeout(() => {
      history.push(to);
    }, 0);

    return handleLinkClick();
  };

  const handleLinkClick = () => {
    closeAllPopup();
    setChatVisible(false);

    return false;
  };

  const { logoUrl, logoHash, firstName, lastName, username } = profile.toJS();
  const queryParams = [`t=${logoHash}`];

  let fullName = `${firstName || ''} ${lastName || ''}`.trim();
  if (fullName === '') fullName = username;

  return (
    <section className={css.container}>
      {isLoading ? null : (
        <div className={css.info}>
          <Image src={getBrandLogoUrl()} placeholder={getBrandLogoAltUrl()} className={css.logo} />
          <Image src={getBrandSmallLogoUrl()} placeholder={getBrandLogoUrl()} className={css.logoSmall} />
          <Link to="/account/profile" className={classNames(css.toggle)} onClick={() => handleNavClick('/account/profile')}>
            <span className={css.avatar}>
              <Image src={`${logoUrl}?${encodeURIComponent(queryParams.join('&'))}`} alt={fullName} />
            </span>
            <span>{fullName}</span>
            <SVG icon="iconCaretDown" className={css.iconCaret} />
          </Link>
        </div>
      )}
      <div className={css.nav}>
        <ul>
          <li>
            <Link activeClassName={css.activeItem} to="/search" onClick={() => handleNavClick('/search')}>
              <SVG icon="iconSearch" className={css.iconSearch} />
              <span>Search</span>
            </Link>
          </li>
          <li>
            <Link activeClassName={css.activeItem} to="/property/group/0" onClick={() => handleNavClick('/property/group/0')}>
              <SVG icon="iconProperties" className={css.iconProperties} />
              <span>My Properties</span>
            </Link>
          </li>
          <li>
            <RestrictedContent permission={Permissions.contactView}>
              <Link activeClassName={css.activeItem} to="/contact/0" onClick={() => handleNavClick('/contact/0')}>
                <SVG icon="iconContacts" className={css.iconContacts} />
                <span>Contacts</span>
              </Link>
            </RestrictedContent>
          </li>
          <li>
            <RestrictedContent permission={Permissions.contactView}>
              <a onClick={handleContactAppendClick}>
                <SVG icon="iconContactAppends" className={css.iconContacts} />
                <span>Skip Tracing</span>
              </a>
            </RestrictedContent>
          </li>
          {!smsEnabled ? null : (
            <li>
              <a onClick={handleSmsClick}>
                <SVG icon="iconSmsBeta" className={css.iconCampaigns} />
                <span>SMS (Beta)</span>
              </a>
            </li>
          )}
          <li>
            <RestrictedContent permission={Permissions.marketingView}>
              <Link activeClassName={css.activeItem} to="/campaign" onClick={() => handleNavClick('/campaign')}>
                <SVG icon="iconCampaigns" className={css.iconCampaigns} />
                <span>Campaigns</span>
              </Link>
            </RestrictedContent>
          </li>
          <li>
            <a target="help" href={getBrandHelpUrl()}>
              <SVG icon="iconQuestion" className={css.iconQuestion} />
              <span>Help</span>
            </a>
          </li>
          <li>
            <Link activeClassName={css.activeItem} to="/account/profile" onClick={() => handleNavClick('/account/profile')}>
              <SVG icon="iconGear" className={css.iconProperties} />
              <span>Settings</span>
            </Link>
          </li>
          {getBrandCode() !== 'lenderdemo' ? null : (
            <li>
              <a onClick={handleLoanApplicationClick}>
                <SVG icon="iconMoney" className={css.iconMoney} />
                <span>Lending</span>
              </a>
            </li>
          )}
          <li>
            <Link to="/logout" onClick={() => handleNavClick('/logout')}>
              <SVG icon="iconPower" className={css.iconQuestion} />
              <span>Log Out</span>
            </Link>
          </li>
          {process.env.NODE_ENV !== 'dev-server' ? null : (
            <li>
              <a onClick={handleTestClick}>
                <span>Test</span>
              </a>
            </li>
          )}
        </ul>
        <ul>
          {getBrandCode() !== 'propstream' ? null : (
            <li>
              <a onClick={handleChatClick}>
                <SVG icon="iconChat" className={css.iconChat} />
                <span>Chat Support</span>
              </a>
            </li>
          )}
        </ul>
      </div>
    </section>
  );
};

export default withRouter(connect((state) => ({
  smsEnabled: selectSmsEnabled(state),
  profile: selectProfile(state),
  isLoading: selectIsLoading(state),
}), {
  authenticate,
  logout,
  setChatVisible,
  closeAllPopup,
  openAppendJobEditor: AppendJobEditor.open,
  closeAppendJobEditor: AppendJobEditor.close,
  openUpgradeAccount: UpgradeAccount.open,
  openLoanApplication: LoanApplication.open,
  openListManagementInfo: ListManagementInfo.open,
  openLaunchControlInfo: LaunchControlInfo.open,
})(onlyUpdateForKeys(KEYS_TO_UPDATE)(Sidebar)));
